import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function createParcel(body: any) {
  const { name } = body;
  const data = {
    name,
  };
  const response = await axios.post(
    `${API_URL}catalogos/delivery-agencies/`,
    data
  );
  return response;
}

export async function getAllParcel() {
  const response = await axios.get(`${API_URL}catalogos/delivery-agencies`);
  const data = response.data.doc.data;
  return data;
}

export async function removeParcel(id: number) {
  const response = await axios.delete(
    `${API_URL}catalogos/delivery-agencies/${id}`
  );
  return response;
}

export async function updateParcel(body: any, id: number) {
  const { name } = body;
  const data = {
    name: name,
  };
  const response = await axios.put(
    `${API_URL}catalogos/delivery-agencies/${id}`,
    data
  );
  return response;
}
