import React from 'react';

type Props = {
  porcentaje: number;
  color: string;
};

const ProgressDash: React.FC<Props> = ({ porcentaje, color }) => {
  return (
    <>
      <div className="d-flex flex-column w-100 pb-2">
        <div className="d-flex flex-stack mb-2">
          <span className="text-muted me-2 fs-9 fw-semibold">
            {porcentaje}%
          </span>
        </div>
        <div className="progress h-7px w-55px">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${porcentaje}%`, backgroundColor: `${color}` }}
          ></div>
        </div>
      </div>
    </>
  );
};

export { ProgressDash };
