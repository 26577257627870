import { useState, useEffect } from 'react';
import {
  getAll,
  getOffers,
  getSelectCodes,
  getSelectUser,
} from '../../../../service/operacion/Activaciones';
import { ActivacionModel } from '../models/ActivacionModel';
import { OffersModel } from '../models/OffersModel';
import { useHistory } from 'react-router-dom';

export const useGetAll = (realoadGrid: number, filters: any) => {
  const [data, setData] = useState<ActivacionModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAll(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid]);
  return { data, loadingRequest };
};

export const useSelectOffers = (show: boolean, filter: string) => {
  const [data, setData] = useState<OffersModel[]>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getOffers(filter)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res);
      }
    };
    if (show) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  return { data };
};

export const useSelectCodes = (show: boolean, showFilterCode: boolean) => {
  const [data, setData] = useState<any[]>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getSelectCodes()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res);
      }
    };
    if (show && showFilterCode) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, showFilterCode]);
  return { data };
};

export const useSelectUser = (show: boolean, showFilterCode: boolean) => {
  const [data, setData] = useState<any[]>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getSelectUser()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res);
      }
    };
    if (show && showFilterCode) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, showFilterCode]);
  return { data };
};
