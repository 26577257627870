import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

const sumDayDate = (date: string) => {
  let fechaInicial = new Date(date);
  fechaInicial.setDate(fechaInicial.getDate() + 1);
  let fechaFormateada = fechaInicial.toISOString().slice(0, 10);
  return fechaFormateada;
};

export async function getActivations(filters: any) {
  let search: string = '';
  if (filters.user_id !== '') {
    search += `&user_id=${filters.user_id}`;
  }
  if (filters.offer_id !== '0') {
    search += `&offer_id=${filters.offer_id}`;
  }
  if (filters.init_date) {
    search += `&init_date=${filters.init_date}`;
  }
  if (filters.end_date) {
    search += `&end_date=${filters.end_date}`;
  }
  const response = await axios.get(
    `${API_URL}reports/activation?sort=id${search}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function downloadPDF(filters: any) {
  let search: string = '';
  let date = new Date().toISOString().split('T')[0];
  if (filters.user_id !== '') {
    search += `&user_id=${filters.user_id}`;
  }
  if (filters.offer_id !== 0) {
    search += `&offer_id=${filters.offer_id}`;
  }
  if (filters.init_date) {
    search += `&init_date=${filters.init_date}`;
  }
  if (filters.end_date) {
    search += `&end_date=${filters.end_date}`;
  }
  const response = await axios({
    url: `${API_URL}reports/activation/document/pdf?sort=id${search}`,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `REPORTE-ACTIVACION-${date}.pdf`);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 0);
      return false;
    })
    .catch(async (error) => {
      return JSON.parse(await error.response.data.text());
    });
  return response;
}

export async function downloadExcel(filters: any) {
  let search: string = '';
  let date = new Date().toISOString().split('T')[0];

  if (filters.init_date) {
    search += `&init_date=${filters.init_date}`;
  }
  if (filters.end_date) {
    search += `&end_date=${filters.end_date}`;
  }
  const response = await axios({
    url: `${API_URL}reports/activation/document/excel?${search}`,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `REPORTE-ACTIVACION-${date}.xlsx`);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 0);
      return false;
    })
    .catch(async (error) => {
      return JSON.parse(await error.response.data.text());
    });
  return response;
}
