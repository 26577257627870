import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { GraficaEnvios } from './GraficaEnvios';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { SimpleAlert, KTSVG } from '../../../../_mexablock/helpers';
import { useSelectCuentas, useInfoEnvios } from '../hooks/DashboardHook';
import { TopUpBalanceModal } from '../../../../_mexablock/partials/modals/balance/TopUpBalanceModal';
import { Offers } from './Offers';
import { useSelectOffers } from '../../operacion/activacion/hooks/ActivacionHook';
import { Carousel } from 'react-bootstrap-v5';
import { useGetAllIndicators } from '../../../modules/profile/hooks/profileHook';
import useSubscription from '../../../../hooks/useSubscription';
import { ProgressDash } from '../../../../_mexablock/helpers/components/ProgressDashboard';
import {
  downloadPDF as pdfActivaciones,
  downloadExcel as excelActivaciones,
} from '../../../service/reportes/Activaciones';
import {
  downloadPDF as pdfPortability,
  downloadExcel as excelPortability,
} from '../../../service/reportes/Portability';
import {
  downloadPDF as pdfRecharge,
  downloadExcel as excelRecargas,
} from '../../../service/reportes/Recargas';
import { OfferDashboard } from '../../../../_mexablock/helpers/components/OfferDashboard';
import { userHasRole } from '../../../../_mexablock/helpers';

const DashboardPage: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const userRoles = user?.roles || [];

  const history = useHistory();
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [reloadGrid] = useState(Math.random() * 40);
  const { data, count, loadingRequest } = useSelectCuentas(reloadGrid);
  const { data: InfoEnvios, loadingEnvios } = useInfoEnvios(reloadGrid);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [dataCharts, setDataCharts] = useState([]);
  const [dataColors, setDataColors] = useState([]);
  const [activeTab, setActiveTab] = useState('todo');
  const [show, setShow] = useState(false);
  const [dataIndicators, setDataIndicators] = useState<any>(null);
  const { dataIndicators: initialIndicators } = useGetAllIndicators(reloadGrid);
  const { data: offers } = useSelectOffers(true, 'recharge');
  const [loadingDownload, setloadingDownload] = useState(false);
  const [loadingDownloadExcel, setloadingDownloadExcel] = useState(false);
  const [validRol, setValidRol] = useState<boolean>(false);
  let fechaActual = new Date();
  let fecha_inicio_init = new Date(
    fechaActual.getFullYear(),
    fechaActual.getMonth(),
    1
  );
  let fecha_fin_init = new Date();

  // const slides = [];
  const itemsPerSlide = 3;

  const [initFilterValues] = useState({
    offer_id: '',
    user_id: '',
    init_date: new Date(fecha_inicio_init).toISOString().split('T')[0],
    end_date: new Date(fecha_fin_init).toISOString().split('T')[0],
    filter: 0,
  });

  useEffect(() => {
    if (userHasRole(['CALL_CENTER', 'REPORTES'], userRoles)) {
      history.push('/operacion/consumos');
    }
  }, []);

  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  useEffect(() => {
    if (count > 0) {
      const dataEffect: any = [];
      const colorsEffect: any = [];
      data.forEach((element: any) => {
        const colors = getRandomHexColor();
        colorsEffect.push(colors);
        dataEffect.push({
          name: element.distributor
            ? element.distributor.first_name
            : element.company.name,
          color: colors,
          data: [
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
          ],
        });
      });
      setDataCharts(dataEffect);
      setDataColors(colorsEffect);
      setShow(true);
    }
  }, [count, data]);

  const getRandomHexColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const getRandomNumber = () => {
    return Math.floor(Math.random() * 10000);
  };
  const filteredOffers = useMemo(() => {
    return offers.filter((offer: any) => {
      if (activeTab === 'semanal') return offer.days === 7;
      if (activeTab === 'mensual') return offer.days === 30;
      if (activeTab === 'anual') return offer.days === 365;
      return true;
    });
  }, [offers, activeTab]);

  const slides = useMemo(() => {
    const itemsPerSlide = 3;
    const result = [];
    for (let i = 0; i < filteredOffers.length; i += itemsPerSlide) {
      result.push(filteredOffers.slice(i, i + itemsPerSlide));
    }
    return result;
  }, [filteredOffers]);

  const handleWebSocketMessage = (message: any) => {
    if (message) {
      setDataIndicators((prevData: any) => {
        const exists = prevData.some((item: any) => item.id === message.id);

        if (exists) {
          return prevData.map((item: any) =>
            item.id === message.id ? message : item
          );
        }

        return [message, ...prevData];
      });
    }
  };

  const handleWebSocketError = (error: any) => {
    console.error('Error del WebSocket:', error);
  };

  useSubscription('operation-activity', {
    onMessage: handleWebSocketMessage,
    onError: handleWebSocketError,
  });

  const handleDownloadPDFAct = async (TypeReport: number) => {
    setloadingDownload(true);
    try {
      if (TypeReport == 1) await pdfActivaciones(initFilterValues);
      if (TypeReport == 2) await pdfPortability(initFilterValues);
      if (TypeReport == 3) await pdfRecharge(initFilterValues);
    } catch (error: any) {
      SimpleAlert(error.message || 'Error al descargar el PDF', 4000, 'error');
    } finally {
      setloadingDownload(false);
    }
  };

  const handleDownloadExcelAct = async (TypeReport: number) => {
    setloadingDownloadExcel(true);
    try {
      if (TypeReport == 1) await excelActivaciones(initFilterValues);
      if (TypeReport == 2) await excelPortability(initFilterValues);
      if (TypeReport == 3) await excelRecargas(initFilterValues);
    } catch (error: any) {
      SimpleAlert(
        error.message || 'Error al descargar el Excel',
        4000,
        'error'
      );
    } finally {
      setloadingDownloadExcel(false);
    }
  };

  const config: Intl.DateTimeFormatOptions = { month: 'long', year: 'numeric' };

  const obtenerPeriodo = () => {
    const fecha = new Date();
    const mes = fecha.toLocaleDateString('es-ES', { month: 'long' });
    const año = fecha.getFullYear();
    return `${mes.charAt(0).toUpperCase() + mes.slice(1)} ${año}`;
  };

  const {
    activations = 0,
    activations_number = 0,
    portabilities = 0,
    portabilities_number = 0,
    recharges = 0,
    recharges_number = 0,
    total = 0,
    oldest_year,
  } = dataIndicators || {};

  const percentageActivations =
    total > 0 ? Number(((activations / total) * 100).toFixed(2)) : 0;
  const percentagePortabilities =
    total > 0 ? Number(((portabilities / total) * 100).toFixed(2)) : 0;
  const percentageRecharges =
    total > 0 ? Number(((recharges / total) * 100).toFixed(2)) : 0;
  useEffect(() => {
    if (initialIndicators) {
      setDataIndicators(initialIndicators);
    }
  }, [initialIndicators]);

  return (
    <>
      {!dataIndicators ? null : (
        <div className="container-fluid px-0">
          <div className="row g-2 g-lg-3 justify-content-between w-100 mb-9">
            <div className="col">
              <div className="card card-flush">
                <div className="card-header pt-5">
                  <div className="card-title d-flex flex-column">
                    <span className="text-primary pb-2 fw-semibold fs-5">
                      Balance
                    </span>
                    <div className="d-flex align-items-center">
                      <span className="fs-1 fw-semibold text-gray-500 me-1 align-self-start">
                        $
                      </span>
                      <span className="fs-1 fw-bold text-gray-900 me-2 lh-1 ls-n2">
                        {total}
                      </span>
                    </div>
                    <span className="fs-9 text-gray-600">
                      Periodo: {obtenerPeriodo()}
                    </span>
                    <div className="d-flex gap-3 w-100 flex-grow-1 pt-2">
                      <ProgressDash
                        porcentaje={percentageActivations}
                        color="#65558f"
                      />
                      <ProgressDash
                        porcentaje={percentagePortabilities}
                        color="#65558fcc"
                      />
                      <ProgressDash
                        porcentaje={percentageRecharges}
                        color="#65558f99"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card card-flush">
                <div className="card-header pt-5">
                  <div className="card-title d-flex flex-column">
                    <span className="text-primary pb-2 fw-semibold fs-5">
                      Activaciones
                    </span>
                    <div className="d-flex align-items-center">
                      <span className="fs-1 fw-semibold text-gray-500 me-1 align-self-start">
                        $
                      </span>
                      <span className="fs-1 fw-bold text-gray-900 me-2 lh-1 ls-n2">
                        {activations}
                      </span>
                    </div>
                    <span className="fs-9 text-gray-600">
                      <span className="text-success fw-bolder">
                        {activations_number}
                      </span>{' '}
                      realizadas exitosamente
                    </span>
                    <div className="d-flex rounded mt-3">
                      <button
                        className="btn btn-sm btn-light-success fs-10"
                        disabled={loadingDownload}
                        onClick={() => handleDownloadExcelAct(1)}
                      >
                        {loadingDownload && data.length ? (
                          <span
                            className="indicator-progress"
                            style={{ display: 'block' }}
                          >
                            Descargando...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        ) : (
                          <>
                            <KTSVG
                              path="/media/icons/duotune/files/fil029.svg"
                              className="svg-icon-5 svg-icon-gray-500 me-1"
                            />
                            Descargar
                          </>
                        )}
                      </button>

                      <button
                        className="btn btn-sm btn-light-danger ms-2 fs-10"
                        disabled={loadingDownload}
                        onClick={() => handleDownloadPDFAct(1)}
                      >
                        {loadingDownload && data.length ? (
                          <span
                            className="indicator-progress"
                            style={{ display: 'block' }}
                          >
                            Descargando...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        ) : (
                          <>
                            <KTSVG
                              path="/media/icons/duotune/files/fil030.svg"
                              className="svg-icon-5 svg-icon-gray-500 me-1"
                            />
                            Descargar
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card card-flush">
                <div className="card-header pt-5">
                  <div className="card-title d-flex flex-column">
                    <span className="text-primary pb-2 fw-semibold fs-5">
                      Portabilidad
                    </span>
                    <div className="d-flex align-items-center">
                      <span className="fs-1 fw-semibold text-gray-500 me-1 align-self-start">
                        $
                      </span>
                      <span className="fs-1 fw-bold text-gray-900 me-2 lh-1 ls-n2">
                        {portabilities}
                      </span>
                    </div>
                    <span className="fs-9 text-gray-600">
                      <span className="text-success fw-bolder">
                        {portabilities_number}
                      </span>{' '}
                      realizadas exitosamente
                    </span>
                    <div className="d-flex rounded mt-3">
                      <button
                        className="btn btn-sm btn-light-success fs-10"
                        disabled={loadingDownload}
                        onClick={() => handleDownloadExcelAct(2)}
                      >
                        {loadingDownload && data.length ? (
                          <span
                            className="indicator-progress"
                            style={{ display: 'block' }}
                          >
                            Descargando...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        ) : (
                          <>
                            <KTSVG
                              path="/media/icons/duotune/files/fil029.svg"
                              className="svg-icon-5 svg-icon-gray-500 me-1"
                            />
                            Descargar
                          </>
                        )}
                      </button>

                      <button
                        className="btn btn-sm btn-light-danger ms-2 fs-10"
                        disabled={loadingDownload}
                        onClick={() => handleDownloadPDFAct(2)}
                      >
                        {loadingDownload && data.length ? (
                          <span
                            className="indicator-progress"
                            style={{ display: 'block' }}
                          >
                            Descargando...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        ) : (
                          <>
                            <KTSVG
                              path="/media/icons/duotune/files/fil030.svg"
                              className="svg-icon-5 svg-icon-gray-500 me-1"
                            />
                            Descargar
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card card-flush">
                <div className="card-header pt-5">
                  <div className="card-title d-flex flex-column">
                    <span className="text-primary pb-2 fw-semibold fs-5">
                      Recargas
                    </span>
                    <div className="d-flex align-items-center">
                      <span className="fs-1 fw-semibold text-gray-500 me-1 align-self-start">
                        $
                      </span>
                      <span className="fs-1 fw-bold text-gray-900 me-2 lh-1 ls-n2">
                        {recharges}
                      </span>
                    </div>
                    <span className="fs-9 text-gray-600">
                      <span className="text-success fw-bolder">
                        {recharges_number}
                      </span>{' '}
                      realizadas exitosamente
                    </span>
                    <div className="d-flex rounded mt-3">
                      <button
                        className="btn btn-sm btn-light-success fs-10"
                        disabled={loadingDownload}
                        onClick={() => handleDownloadExcelAct(3)}
                      >
                        {loadingDownload && data.length ? (
                          <span
                            className="indicator-progress"
                            style={{ display: 'block' }}
                          >
                            Descargando...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        ) : (
                          <>
                            <KTSVG
                              path="/media/icons/duotune/files/fil029.svg"
                              className="svg-icon-5 svg-icon-gray-500 me-1"
                            />
                            Descargar
                          </>
                        )}
                      </button>

                      <button
                        className="btn btn-sm btn-light-danger ms-2 fs-10"
                        disabled={loadingDownload}
                        onClick={() => handleDownloadPDFAct(3)}
                      >
                        {loadingDownload && data.length ? (
                          <span
                            className="indicator-progress"
                            style={{ display: 'block' }}
                          >
                            Descargando...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        ) : (
                          <>
                            <KTSVG
                              path="/media/icons/duotune/files/fil030.svg"
                              className="svg-icon-5 svg-icon-gray-500 me-1"
                            />
                            Descargar
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row mb-9">
        <div className="col-4">
          <OfferDashboard />
        </div>
      </div>

      <div className="row mb-9">
        <div className="col-4">
          <GraficaEnvios data={InfoEnvios} loadingGrid={loadingGrid} />
        </div>
      </div>
      <div className="row g-6 g-xl-9">
        <h2>MIS PLANES</h2>
        <div className="d-flex justify-content-center align-items-center">
          <ul className="nav">
            <li className="nav-item">
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${
                  activeTab === 'todo' ? 'active' : ''
                }`}
                onClick={() => setActiveTab('todo')}
              >
                Todo
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${
                  activeTab === 'semanal' ? 'active' : ''
                }`}
                onClick={() => setActiveTab('semanal')}
              >
                Semanal
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${
                  activeTab === 'mensual' ? 'active' : ''
                }`}
                onClick={() => setActiveTab('mensual')}
              >
                Mensual
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${
                  activeTab === 'anual' ? 'active' : ''
                }`}
                onClick={() => setActiveTab('anual')}
              >
                Anual
              </a>
            </li>
          </ul>
        </div>
        <Carousel
          controls={true}
          indicators={true}
          interval={4000}
          className="carousel-dark"
          key={activeTab}
        >
          {slides.map((slide, index) => (
            <Carousel.Item key={index}>
              <div className="row row-cols-2 row-cols-lg-5 g-2 g-lg-3 justify-content-center">
                {slide.map((item: any) => (
                  <div key={item.id} className="col">
                    <Offers data={item} />
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>

        {/* {userHasRole(['ADMIN_MEXABLOCK', 'ADMIN_PARTNER'], userRoles) &&
          dataCharts.length > 0 && (
            <div className="col-xl-8">
              <GraficaTransacciones
                series={dataCharts}
                colors={dataColors}
                show={show}
                height={350}
                className="card card-flush h-xl-100"
              />
            </div>
          )} */}
        {/* {userHasRole(['ADMIN_MEXABLOCK', 'ADMIN_PARTNER'], userRoles) && (
          <div className="col-xl-4">
            <AsignarSaldo
              data={data}
              tipoRole={rol}
              callback={(updateData: boolean) => {
                if (updateData) {
                  setReloadGrid(Math.random() * 40);
                }
              }}
            />
          </div>
        )} */}
        {/* {userHasRole(
          ['ADMIN_COMPANY', 'ADMIN_PARTNER', 'DISTRIBUTOR'],
          userRoles
        ) && (
          <div className="col-xl-4">
            <BannerSaldo
              data={{}}
              callback={() => {
                setVisivilityModal(!visivilityModal);
              }}
            />
          </div>
        )}
        {userHasRole(['ADMIN_MEXABLOCK', 'ADMIN_PARTNER'], userRoles) && (
          <div className="col-xl-4">
            <SaldosEmpresas
              data={data}
              loadingGrid={loadingGrid}
              tipoRole={rol}
            />
          </div>
        )} */}
      </div>
      <TopUpBalanceModal
        show={visivilityModal}
        handleClose={(updateData: boolean) => {
          setVisivilityModal(!visivilityModal);
        }}
      />
    </>
  );
};

export { DashboardPage };
