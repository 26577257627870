import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

const sumDayDate = (date: string) => {
  let fechaInicial = new Date(date);
  fechaInicial.setDate(fechaInicial.getDate() + 1);
  let fechaFormateada = fechaInicial.toISOString().slice(0, 10);
  return fechaFormateada;
};

export async function getAccountStatment(filters: any) {
  let search: string = '';

  if (filters.init_date) {
    search += `?init_date=${filters.init_date}`;
  }
  if (filters.end_date) {
    search += `&end_date=${filters.end_date}`;
  }
  if (filters.user_id !== '') {
    search += `&user_id=${filters.user_id}`;
  }
  const response = await axios.get(`${API_URL}operation/activity${search}`);
  const data = response.data.doc.data;
  return data;
}

export async function downloadPDF(filters: any) {
  let search: string = '';
  let date = new Date().toISOString().split('T')[0];

  if (filters.init_date) {
    search += `&init_date=${filters.init_date}`;
  }
  if (filters.end_date) {
    search += `&end_date=${filters.end_date}`;
  }
  if (filters.user_id !== '') {
    search += `&user_id=${filters.user_id}`;
  }
  const response = await axios({
    url: `${API_URL}operation/activity/month-pdf?${search}`,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `REPORTE-ESTADO-DE-CUENTA-${date}.pdf`);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 0);
      return false;
    })
    .catch(async (error) => {
      return JSON.parse(await error.response.data.text());
    });
  return response;
}
