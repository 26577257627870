import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import * as Yup from 'yup';
import { StepperComponent } from '../../../../../_mexablock/assets/ts/components';
import { useFormik } from 'formik';
import AnimationSucces from '../../../../../_mexablock/helpers/components/SuccesResponse';
import {
  KTSVG,
  toAbsoluteUrl,
  SimpleAlert,
  maskPhoneNumber,
  cleanPhoneNumber,
} from '../../../../../_mexablock/helpers';
import { Modal } from 'react-bootstrap-v5';
import {
  validateEmail,
  nuevaPortabilidadAct,
  searchUser,
} from '../../../../service/operacion/Validacion';
import { useSelectGeneric } from '../../../catalogos/generico/hooks/genericoHook';
import { getUserByToken } from '../../../../modules/auth/redux/AuthCRUD';
import * as auth from '../../../../modules/auth/redux/AuthRedux';

type Props = {
  show: boolean;
  handleClose: (updateData: boolean) => void;
};
const schemaNewPortability = [
  Yup.object({
    iccid: Yup.string()
      .required('Requerido.')
      .min(19, 'Formato de ICCID inválido')
      .max(19, 'Formato de ICCID inválido'),
    // found: Yup.number().min(1, 'Requerido.').required('Requerido.'),
  }),
  // Yup.object({
  //   phoneFormat: Yup.string()
  //     .required('Requerido.')
  //     .min(10, 'Formato de teléfono inválido'),
  //   nip: Yup.string()
  //     .min(4, 'NIP debe tener 4 dígitos.')
  //     .required('Requerido.'),
  //   phoneContactFormat: Yup.string()
  //     .required('Requerido.')
  //     .min(10, 'Formato de teléfono inválido'),
  // }),
  Yup.object({
    first_name: Yup.string()
      .max(20, 'Máximo 20 caracteres')
      .required('Requerido.'),
    mother_last_name: Yup.string()
      .max(20, 'Máximo 20 caracteres')
      .required('Requerido.'),
    father_last_name: Yup.string()
      .max(20, 'Máximo 20 caracteres')
      .required('Requerido.'),
    email: Yup.string()
      .email('Formato de correo inválido.')
      .min(3, 'Mínimo 3 caracteres.')
      .max(50, 'Máximo 50 caracteres.')
      .required('Requerido.'),
    birthdate: Yup.date().required('Requerido.'),
    gender_id: Yup.number().min(1, 'Requerido.').required('Requerido.'),
  }),
  Yup.object({
    imei: Yup.string().when('found', {
      is: 2,
      then: Yup.string()
        .min(15, 'El IMEI debe tener 15 dígitos.')
        .required('Requerido.'),
      otherwise: Yup.string(),
    }),
    phoneFormat: Yup.string()
      .required('Requerido.')
      .min(10, 'Formato de teléfono inválido'),
    nip: Yup.string()
      .matches(/^\d{4}$/, 'NIP debe tener 4 dígitos.')
      .required('Requerido.'),
  }),
];

let maxDate = new Date();
maxDate.setDate(maxDate.getDate() + 1);

const currentDate = new Date();
const fechaMaxima = new Date(currentDate);
fechaMaxima.setFullYear(currentDate.getFullYear() - 18);
const fechaMaximaString = fechaMaxima.toISOString().split('T')[0];

const initValues = {
  // imei: '',
  iccid_real: '',
  iccid: '',
  found: 0,
  activation_id: 0,

  first_name: '',
  mother_last_name: '',
  father_last_name: '',
  email: '',
  birthdate: fechaMaxima,
  gender_id: 0,

  imei: '',
  phone: '',
  phoneFormat: '',
  nip: '',
  fecha_portabilidad: maxDate.toISOString().split('T')[0],
};

const getNextAvailableDate = (): string => {
  const today = new Date();
  let nextDay = new Date(today);
  nextDay.setDate(today.getDate() + 1);
  if (nextDay.getDay() === 0) {
    nextDay.setDate(nextDay.getDate() + 1);
  }
  return nextDay.toISOString().split('T')[0];
};

const PortabilityActivationModal: React.FC<Props> = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  // STEPPER
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(schemaNewPortability[0]);
  const [textStepper, setTextStepper] = useState('Continuar');
  const [loading, setLoading] = useState(false);
  const [disabledSearch, setDisabledSearch] = useState(false);
  const [infoClient, setInfoClient] = useState('');
  const [portabilityComplete, setPortabilityComplete] = useState<number>(0);
  const { catalog: genders } = useSelectGeneric('GEN');

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };
  useEffect(() => {
    if (show) {
      loadStepper();
    }
  }, [stepperRef, show]);

  const formNewPortability = useFormik({
    initialValues: initValues,
    validationSchema: currentSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      if (!stepper.current) {
        return;
      }
      switch (stepper.current.currentStepIndex) {
        case 1:
          setLoading(true);
          setTimeout(() => {
            if (!stepper.current) {
              return;
            }
            if (values.found === 1) {
              stepper.current.goto(3);
              setTextStepper('Enviar a portar');
              setCurrentSchema(schemaNewPortability[2]);
              setLoading(false);
            } else if (values.found === 2) {
              stepper.current.goto(2);
              setLoading(false);
              setCurrentSchema(schemaNewPortability[1]);
            } else {
              SimpleAlert(
                'Favor realice la busqueda con un ICCID.',
                3000,
                'error'
              );
              setLoading(false);
            }
          }, 1000);

          break;
        case 2:
          setLoading(true);
          setTimeout(() => {
            validateEmail(values)
              .then(async (res: any) => {
                if (!stepper.current) {
                  return;
                }
                const { disponible, mensaje } = res.data.doc.data;
                if (disponible) {
                  stepper.current.goNext();
                  setTextStepper('Iniciar portabilidad');
                  setCurrentSchema(schemaNewPortability[2]);
                  setLoading(false);
                } else {
                  SimpleAlert(mensaje, 3000, 'error');
                  setLoading(false);
                }
              })
              .catch((err) => {
                let resMessageToast: string = '';
                const {
                  data: { message },
                } = err.response;
                resMessageToast = `${message}`;
                SimpleAlert(resMessageToast, 3000, 'error');
                setLoading(false);
              });
          }, 1000);
          break;
        case 3:
          setLoading(true);
          setTimeout(() => {
            nuevaPortabilidadAct(values)
              .then(async (res) => {
                if (!stepper.current) {
                  return;
                }
                stepper.current.goNext();
                setPortabilityComplete(1);
                setTextStepper('Finalizar');
                setCurrentSchema(schemaNewPortability[3]);
                setLoading(false);

                // UPDATE SALDO
                const user = await getUserByToken();
                dispatch(auth.actions.fulfillUser(user));
              })
              .catch((err) => {
                let resMessageToast: string = '';
                const {
                  data: { message, details = null },
                } = err.response;
                resMessageToast = `${message}`;
                if (details.length) {
                  resMessageToast = details[0]?.message;
                }
                SimpleAlert(resMessageToast, 3000, 'error');
                setLoading(false);
              });
          }, 1000);
          break;
        case 4:
          if (!stepper.current) {
            return;
          }
          handleClose(true);
          stepper.current.goto(1);
          setTextStepper('Continuar');
          setPortabilityComplete(0);
          setCurrentSchema(schemaNewPortability[0]);
          formNewPortability.resetForm();
          setLoading(false);
          break;
      }
    },
  });

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    if (formNewPortability.values.found === 1) {
      //EXISTE USUARIO
      stepper.current.goFirst();
      setCurrentSchema(schemaNewPortability[0]);
    } else {
      stepper.current?.goPrev();
      setCurrentSchema(
        schemaNewPortability[stepper.current.currentStepIndex - 1]
      );
    }
    switch (stepper.current.currentStepIndex - 1) {
      case 1:
        setTextStepper('Continuar');
        break;
      case 2:
        setTextStepper('Continuar');
        break;
      case 3:
        setTextStepper('Continuar');
        break;
      default:
        setTextStepper('Continuar');
    }
  };

  const handlePhoneChange = (values: any) => {
    const value = values.target.value.toString();
    const maskedPhoneNumber = maskPhoneNumber(value);

    formNewPortability.setFieldValue('phone', maskedPhoneNumber);
    formNewPortability.setFieldValue(
      'phoneFormat',
      cleanPhoneNumber(maskedPhoneNumber)
    );
  };

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const value = event.target.value;
    const valueReg = value.replace(/\d/g, '');
    const cleanInput = valueReg.replace(/^\s+|\s+(?=\s)/g, '');
    const inputLimpio = cleanInput.replace(/[^\wáéíóúüÁÉÍÓÚÜñÑ\s]/g, '');
    formNewPortability.setFieldValue(key, inputLimpio);
  };

  const handleSearchUser = () => {
    const iccid = formNewPortability.values.iccid;
    if (iccid.length >= 19) {
      setDisabledSearch(true);
      setTimeout(() => {
        searchUser(formNewPortability.values.iccid)
          .then(async (res) => {
            setDisabledSearch(false);
            const { found, user } = res.data.doc.data;
            formNewPortability.setFieldValue('found', found ? 1 : 2); //1 existe usuario, 2 no existe
            formNewPortability.setFieldValue(
              'activation_id',
              user?.activation_id ? user.activation_id : 0
            );
            formNewPortability.setFieldValue(
              'iccid_real',
              formNewPortability.values.iccid
            );
            setInfoClient(
              user
                ? `${user.first_name} ${user.father_last_name} ${user.mother_last_name}`
                : ''
            );
            setDisabledSearch(false);
          })
          .catch((err) => {
            let resMessageToast: string = '';
            const {
              data: { message },
            } = err.response;
            resMessageToast = `${message}`;
            SimpleAlert(resMessageToast, 3000, 'error');

            formNewPortability.setFieldValue('found', 0);
            formNewPortability.setFieldValue('activation_id', 0);
            formNewPortability.setFieldValue('iccid_real', '');
            setInfoClient('');
            setDisabledSearch(false);
          });
      }, 1000);
    } else {
      SimpleAlert('Favor ingresa un ICCID válido.', 3000, 'error');
    }
  };

  return (
    <Modal
      id="kt_modal_portability"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-xl"
      show={show}
      onHide={() => {
        handleClose(true);
        setTimeout(() => {
          stepper?.current?.goto(1);
          formNewPortability.resetForm();
          setCurrentSchema(schemaNewPortability[0]);
          setTextStepper('Continuar');
          setPortabilityComplete(0);
        }, 1000);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              stepper?.current?.goto(1);
              formNewPortability.resetForm();
              setCurrentSchema(schemaNewPortability[0]);
              setTextStepper('Continuar');
              setPortabilityComplete(0);
              handleClose(true);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body pt-0">
          <div className="text-center mb-13">
            <h1 className="mb-3">Nueva Portabilidad</h1>
          </div>
          <div
            ref={stepperRef}
            className="stepper stepper-links d-flex flex-column gap-5 first"
            id="kt_modal_create_app_stepper"
          >
            <div className="stepper-nav justify-content-center py-2">
              <div
                className="stepper-item current"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Validación ICCID</h3>
              </div>
              {/* <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Datos de la Portabilidad</h3>
              </div> */}
              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Datos del Cliente</h3>
              </div>
              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Datos de la Portabilidad</h3>
              </div>

              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Completada</h3>
              </div>
            </div>
            <form
              className="mx-auto w-100 mw-800px pt-10 pb-10"
              id="kt_modal_top_up_wallet_stepper_form"
              autoComplete="off"
              onSubmit={formNewPortability.handleSubmit}
            >
              <div
                data-kt-stepper-element="content"
                className="current min-h-550px"
              >
                <div className="w-100 ms-20 me-20 h-auto">
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="w-100 d-flex">
                      <label className="required fs-6 fw-semibold form-label mb-2">
                        ICCID
                      </label>
                      {formNewPortability.touched.iccid &&
                        formNewPortability.errors.iccid && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formNewPortability.errors.iccid}
                            </span>
                          </div>
                        )}
                    </div>
                    <div className="row">
                      <div className="col-md-9">
                        <NumericFormat
                          className="form-control mb-3"
                          {...formNewPortability.getFieldProps('iccid')}
                          decimalScale={0}
                          maxLength={19}
                          allowNegative={false}
                          fixedDecimalScale
                          placeholder="Identificador (ICCID)"
                          onValueChange={(values: any) => {
                            const { floatValue } = values;
                            formNewPortability.setFieldValue(
                              'iccid',
                              floatValue || ''
                            );
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <button
                          className="btn btn-sm btn-primary mt-2"
                          onClick={handleSearchUser}
                          disabled={disabledSearch}
                        >
                          {!disabledSearch && (
                            <>
                              <span className="indicator-label">Buscar</span>
                            </>
                          )}
                          {disabledSearch && (
                            <span
                              className="indicator-progress"
                              style={{ display: 'block' }}
                            >
                              Buscando...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                        {/* <button
                          disabled={disabledSearch}
                          className="btn btn-sm btn-primary mt-2"
                          onClick={handleSearchUser}
                        >
                          Buscar
                        </button> */}
                      </div>
                    </div>
                  </div>
                  <div className="fv-row mb-7">
                    {formNewPortability.values.found > 0 && (
                      <div className="w-100">
                        <div className="pb-5 pb-lg-10">
                          <div className="text-muted fw-semibold fs-6">
                            {formNewPortability.values.found === 1
                              ? 'El ICCD está relacionado a este usuario'
                              : 'El ICCD no está relacionado'}
                          </div>
                        </div>
                        <div className="d-flex flex-column mb-10 ">
                          <div className="d-flex align-items-center gap-5">
                            <div className="symbol symbol-50px symbol-lg-50px symbol-fixed position-relative">
                              <img
                                src={toAbsoluteUrl('/media/avatars/blank.png')}
                                alt="Metornic"
                              />
                            </div>

                            <div className="fw-bold">
                              <span className="fw-bold d-flex align-items-center text-primary fs-4">
                                {formNewPortability.values.found === 1
                                  ? infoClient
                                  : 'Sin registro'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                data-kt-stepper-element="content"
                className="pending min-h-550px"
              >
                <div className="w-100 ms-20 me-20 h-auto">
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Nombre
                          </label>
                          {formNewPortability.touched.first_name &&
                            formNewPortability.errors.first_name && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewPortability.errors.first_name}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          placeholder="Nombre"
                          {...formNewPortability.getFieldProps('first_name')}
                          className="form-control mb-3"
                          type="text"
                          name="first_name"
                          autoComplete="off"
                          onChange={(e: any) => {
                            handleChangeInput(e, 'first_name');
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Apellido paterno
                          </label>
                          {formNewPortability.touched.father_last_name &&
                            formNewPortability.errors.father_last_name && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewPortability.errors.father_last_name}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          placeholder="Apellido paterno"
                          {...formNewPortability.getFieldProps(
                            'father_last_name'
                          )}
                          className="form-control mb-3"
                          type="text"
                          name="father_last_name"
                          autoComplete="off"
                          onChange={(e: any) => {
                            handleChangeInput(e, 'father_last_name');
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Apellido materno
                          </label>
                          {formNewPortability.touched.mother_last_name &&
                            formNewPortability.errors.mother_last_name && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewPortability.errors.mother_last_name}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          placeholder="Apellido materno"
                          {...formNewPortability.getFieldProps(
                            'mother_last_name'
                          )}
                          className="form-control mb-3"
                          type="text"
                          name="mother_last_name"
                          autoComplete="off"
                          onChange={(e: any) => {
                            handleChangeInput(e, 'mother_last_name');
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Fecha de nacimiento
                          </label>
                          {formNewPortability.touched.birthdate &&
                            formNewPortability.errors.birthdate && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewPortability.errors.birthdate}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          type="date"
                          className="form-control"
                          onKeyDown={(e) => e.preventDefault()}
                          value={
                            new Date(formNewPortability.values.birthdate)
                              .toISOString()
                              .split('T')[0]
                          }
                          max={fechaMaximaString}
                          onChange={(e) => {
                            if (e.target.value) {
                              const fecha = new Date(e.target.value)
                                .toISOString()
                                .split('T')[0];
                              formNewPortability.setFieldValue(
                                'birthdate',
                                fecha
                              );
                            }
                          }}
                        ></input>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Genero
                          </label>
                          {formNewPortability.touched.gender_id &&
                            formNewPortability.errors.gender_id && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewPortability.errors.gender_id}
                                </span>
                              </div>
                            )}
                        </div>
                        <select
                          className="form-select"
                          {...formNewPortability.getFieldProps('gender_id')}
                        >
                          <option value={0}>Seleccione</option>
                          {genders.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Correo
                          </label>
                          {formNewPortability.touched.email &&
                            formNewPortability.errors.email && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewPortability.errors.email}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          placeholder="Correo"
                          {...formNewPortability.getFieldProps('email')}
                          className="form-control mb-3 mb-lg-0"
                          type="text"
                          name="email"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-kt-stepper-element="content"
                className="pending min-h-550px"
              >
                <div className="w-100 ms-20 me-20 h-auto">
                  {formNewPortability.values.found === 2 && (
                    <div className="fv-row mb-7 fv-plugins-icon-container">
                      <div className="w-100 d-flex">
                        <label className="required fs-6 fw-semibold form-label mb-2">
                          IMEI
                        </label>
                        {formNewPortability.touched.imei &&
                          formNewPortability.errors.imei && (
                            <div className="fv-plugins-message-container text-danger w-auto ms-5">
                              <span role="alert">
                                {formNewPortability.errors.imei}
                              </span>
                            </div>
                          )}
                      </div>

                      <NumericFormat
                        name="imei"
                        className="form-control text-gray-700 placeholder-gray-400"
                        value={formNewPortability.values.imei}
                        decimalScale={0}
                        decimalSeparator="."
                        maxLength={15}
                        fixedDecimalScale
                        placeholder="IMEI"
                        prefix=""
                        thousandSeparator=""
                        onValueChange={(values) => {
                          const { value } = values;
                          formNewPortability.setFieldValue(
                            'imei',
                            value ? value : ''
                          );
                        }}
                      />
                    </div>
                  )}

                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="w-100 d-flex">
                      <label className="required fs-6 fw-semibold form-label mb-2">
                        Número a portar
                      </label>
                      {formNewPortability.touched.phoneFormat &&
                        formNewPortability.errors.phoneFormat && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formNewPortability.errors.phoneFormat}
                            </span>
                          </div>
                        )}
                    </div>

                    <input
                      className="form-control mb-3"
                      value={formNewPortability.values.phone}
                      placeholder="Número a portar"
                      onChange={handlePhoneChange}
                    />
                    <div className="form-text">
                      Este número será utilizado para iniciar el proceso de
                      portabilidad y transferir tu línea. Asegúrate de que el
                      número esté correctamente escrito y que no contenga
                      errores, ya que este dato es esencial para completar la
                      solicitud de forma exitosa.
                    </div>
                  </div>
                  <div className="fv-row mb-7">
                    <div className="w-100 d-flex">
                      <label
                        className="required fs-6 fw-semibold form-label mb-2"
                        htmlFor="nip"
                      >
                        NIP
                      </label>
                      {formNewPortability.touched.nip &&
                        formNewPortability.errors.nip && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formNewPortability.errors.nip}
                            </span>
                          </div>
                        )}
                    </div>

                    <NumericFormat
                      id="nip"
                      className="form-control mb-3"
                      {...formNewPortability.getFieldProps('nip')}
                      decimalScale={0}
                      fixedDecimalScale
                      placeholder="NIP de portabilidad"
                      isAllowed={(values: any) => {
                        const { floatValue = 0 } = values;
                        return floatValue <= 9999;
                      }}
                      onValueChange={(values: any) => {
                        const { floatValue } = values;
                        formNewPortability.setFieldValue('nip', floatValue);
                      }}
                    />
                    <div className="form-text">
                      Para obtener su <span className="fw-bold"> NIP</span> de
                      portabilidad, envie un mensaje al{' '}
                      <span className="fw-bold"> 051</span> con la palabra{' '}
                      <span className="fw-bold"> NIP</span> ó marque al{' '}
                      <span className="fw-bold"> 051</span> y solicite su{' '}
                      <span className="fw-bold"> NIP</span>
                    </div>
                  </div>

                  <div className="fv-row mb-7">
                    <div className="w-100 d-flex">
                      <label
                        className="required fs-6 fw-semibold form-label mb-2"
                        htmlFor="fecha_portabilidad"
                      >
                        Fecha portabilidad
                      </label>
                    </div>
                    <input
                      id="fecha_portabilidad"
                      type="date"
                      onClick={(e) => e.preventDefault()}
                      onKeyDown={(e) => e.preventDefault()}
                      className="form-control form-control-solid"
                      value={getNextAvailableDate()}
                      min={maxDate.toISOString().split('T')[0]}
                      onChange={(e) => {
                        if (e.target.value) {
                          const fecha = new Date(e.target.value)
                            .toISOString()
                            .split('T')[0];
                          formNewPortability.setFieldValue(
                            'fecha_portabilidad',
                            fecha
                          );
                        }
                      }}
                    ></input>
                    <div className="form-text">
                      Recuerda que la portabilidad no se realizará en domingos
                      ni días festivos. Asimismo, ten en cuenta que el proceso
                      se completará en un plazo máximo de 24 horas.
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-kt-stepper-element="content"
                className="pending min-h-550px"
              >
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                  <h1 className="text-primary fw-bolder text-center">
                    Se envío la portabilidad con exito!
                  </h1>

                  <div className="w-350px rounded mx-auto d-block">
                    <AnimationSucces />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-stack pt-10">
                <div className="me-2">
                  {portabilityComplete === 0 && (
                    <button
                      onClick={prevStep}
                      type="button"
                      className="btn btn-lg btn-light-primary me-3"
                      data-kt-stepper-action="previous"
                    >
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr063.svg"
                        className="svg-icon-4 me-1"
                      />
                      Regresar
                    </button>
                  )}
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary me-3"
                    disabled={loading}
                  >
                    {!loading && (
                      <>
                        <span className="indicator-label">{textStepper}</span>
                        {textStepper === 'Continuar' && (
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr064.svg"
                            className="svg-icon-4 me-1 ms-1"
                          />
                        )}
                      </>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: 'block' }}
                      >
                        Espere por favor...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { PortabilityActivationModal };
