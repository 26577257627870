/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef, useState, useMemo } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { getCSSVariableValue } from '../../assets/ts/_utils';
import { useGetAll } from '../../../app/pages/reportes/estado_cuenta/hooks/AccountStatmentHook';
import { useHistory } from 'react-router-dom';

const OfferDashboard: React.FC = () => {
  const history = useHistory();
  let fechaActual = new Date();
  let fecha_inicio_init = new Date(
    fechaActual.getFullYear(),
    fechaActual.getMonth(),
    1
  );
  let fecha_fin_init = new Date();
  const [initFilterValues] = useState({
    user_id: '',
    init_date: new Date(fecha_inicio_init).toISOString().split('T')[0],
    end_date: new Date(fecha_fin_init).toISOString().split('T')[0],
    filter: 0,
  });
  const [selectedOption, setSelectedOption] = useState<number>(1);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const { data } = useGetAll(reloadGrid, initFilterValues);
  const [dataRoute, setDataRoute] = useState<string>('/operacion/activaciones');
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(Number(event.target.value));
    switch (Number(event.target.value)) {
      case 1:
        setDataRoute('/operacion/activaciones');
        break;
      case 2:
        setDataRoute('/operacion/portabilidades');
        break;
      case 3:
        setDataRoute('/operacion/recargas');
        break;
    }

    console.log('Seleccionaste:', event.target.value);
  };
  const colors = ['#883DCF', '#AC77DE', '#CFB1EC', '#E7D8F5'];
  const filteredData = useMemo(() => {
    return data.filter((item: any) => {
      if (selectedOption === 1) return item.Operación === 'Activacion';
      if (selectedOption === 2) return item.Operación === 'Portabilidad';
      if (selectedOption === 3) return item.Operación === 'Recarga web';
      return false;
    });
  }, [data, selectedOption]);

  // 🔥 Contar registros por oferta
  const offerCounts = useMemo(() => {
    return filteredData.reduce((acc: Record<string, number>, item: any) => {
      acc[item.Oferta] = (acc[item.Oferta] || 0) + 1;
      return acc;
    }, {});
  }, [filteredData]);

  const totalRegistros = filteredData.length;

  const offersArray = useMemo(() => {
    return Object.entries(offerCounts)
      .map(([name, cantidad], index) => ({
        name,
        cantidad,
        porcentaje:
          totalRegistros > 0
            ? ((cantidad / totalRegistros) * 100).toFixed(2)
            : '0',
        color: colors[index % colors.length],
      }))
      .slice(0, 4);
  }, [offerCounts, totalRegistros]);

  console.log('Cantidad de registros por oferta:', offersArray);

  const chartRef = useRef<HTMLDivElement | null>(null);

  const chartSeries = useMemo(() => {
    return offersArray.map(({ porcentaje }) => parseFloat(porcentaje));
  }, [offersArray]);

  const chartLabels = useMemo(() => {
    return offersArray.map(({ name }) => name);
  }, [offersArray]);

  const refreshChart = () => {
    if (!chartRef.current) return;
    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartSeries, chartLabels)
    );

    if (chart) {
      chart.render();
    }
    return chart;
  };

  // useEffect(() => {
  //   const chart = refreshChart();

  //   return () => {
  //     if (chart) {
  //       chart.destroy();
  //     }
  //   };
  // }, [chartSeries, chartLabels]);
  useEffect(() => {
    if (!chartRef.current || chartSeries.length === 0) return;

    chartRef.current.innerHTML = '';

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartSeries, chartLabels)
    );

    chart.render();

    return () => {
      chart.destroy();
    };
  }, [chartSeries, chartLabels, data]);

  return (
    <div className="card card-flush">
      <div className="card-header pt-5 d-flex justify-content-center align-items-center">
        <select
          className="form-select form-select-sm w-auto h-40px w-150px"
          value={selectedOption}
          onChange={handleChange}
        >
          <option value={1}>Activaciones</option>
          <option value={2}>Portabilidades</option>
          <option value={3}>Recargas</option>
        </select>
      </div>

      <div className="card-body pt-2 pb-4 d-flex flex-column align-items-center">
        <div className="d-flex justify-content-center align-items-center w-100 mb-4">
          <div
            ref={chartRef}
            style={{
              width: '150px',
              height: '150px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          ></div>
        </div>
        <div className="d-flex flex-column w-100 mt-5">
          {offersArray.map(({ name, cantidad, porcentaje, color }) => (
            <div
              key={name}
              className="d-flex fw-semibold align-items-center justify-content-between pb-2 fs-7"
              style={{ borderBottom: '1px dashed #ccc', paddingBottom: '5px' }}
            >
              <div
                className="d-flex align-items-center fw-bolder"
                style={{ width: '90px' }}
              >
                <div
                  style={{
                    width: '8px',
                    height: '8px',
                    backgroundColor: color,
                    borderRadius: '50%',
                    marginRight: '2px',
                  }}
                ></div>{' '}
                {name}
              </div>
              <div className="fw-bolder me-4">{cantidad}</div>
              <div className="fw-bolder text-end">{porcentaje} %</div>
            </div>
          ))}
        </div>
        <div className="mt-5">
          <button
            className="btn btn-sm btn-light-primary fs-6"
            onClick={() => {
              history.push(dataRoute);
            }}
          >
            Ver detalles
            <i className="bi bi-arrow-right ms-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

const chartOptions = (series: number[], labels: string[]): ApexOptions => {
  const customColors = ['#883DCF', '#AC77DE', '#CFB1EC', '#E7D8F5'];

  return {
    series,
    labels,
    chart: {
      width: '250px',
      type: 'donut',
    },
    colors: customColors,
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        customScale: 0.85,
        donut: {
          size: '75%',
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: '22px',
              fontWeight: 'bold',
              color: '#000',
              formatter: function (val) {
                return val + '%';
              },
            },
            total: {
              show: true,
              label: 'Total',
              fontSize: '18px',
              fontWeight: 'bold',
              color: '#333',
              formatter: function (w) {
                return (
                  w.globals.seriesTotals.reduce((a: any, b: any) => a + b, 0) +
                  '%'
                );
              },
            },
          },
        },
      },
    },
    stroke: {
      show: true,
      width: 5,
      colors: ['#fff'],
      lineCap: 'round',
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val, opts) {
          return `${val.toFixed(2)}%`;
        },
      },
    },
  };
};

export { OfferDashboard };
