import React, { useState, useEffect } from 'react';
import { useGetAll } from '../hooks/ParcelHook';
import { CreateParcelModal } from '../_modals/CreateParcelModal';
import { TableList } from '../../../../../_mexablock/helpers/components/TableList';
import { MenuComponent } from '../../../../../_mexablock/assets/ts/components';
import { LoadingGrid } from '../../../../../_mexablock/helpers';
import { RemoveModal } from '../_modals/RemoveParcelModal';
import { UpdateParcelModal } from '../_modals/UpdateParcelModal';

const View: React.FC = () => {
  const [loadingView, setloadingView] = useState(true);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [dataUpdModal, setDataUpdModal] = useState();
  const [dataActModal, setDataActModal] = useState<number>(0);
  const [visivilityModalUpd, setVisivilityModalUpd] = useState<boolean>(false);
  const [visivilityModalAct, setVisivilityModalAct] = useState<boolean>(false);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const { data, loadingRequest } = useGetAll(reloadGrid);

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
  }, [loadingRequest]);

  const addRegister = () => {
    setVisivilityModal(!visivilityModal);
  };

  const updRegister = (data: any) => {
    setDataUpdModal(data);
    setVisivilityModalUpd(true);
  };

  const actRegister = (id: number) => {
    setDataActModal(id);
    setVisivilityModalAct(true);
  };

  const columns: any = [
    {
      id: 'created_at',
      name: <div className="fw-bolder text-muted me-5">ID</div>,
      selector: (row: any) => row.id,
      sortable: true,
    },
    {
      id: 'created_at',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) => row.name,
      sortable: true,
    },
    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => dropdownRender(row),
      sortable: false,
    },
  ];

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  const dropdownRender = (data: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#/"
            data-kt-menu="true"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            Acciones
          </a>

          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#/"
                onClick={() => {
                  updRegister(data);
                }}
              >
                Editar
              </a>
            </div>
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                data-kt-users-table-filter="delete_row"
                href="#/"
                onClick={() => {
                  actRegister(data.id);
                }}
              >
                Eliminar
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
      <CreateParcelModal
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModal(!visivilityModal);
        }}
      ></CreateParcelModal>
      <UpdateParcelModal
        dataParcel={dataUpdModal}
        show={visivilityModalUpd}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModalUpd(!visivilityModalUpd);
        }}
      />
      <RemoveModal
        id_row={dataActModal}
        show={visivilityModalAct}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModalAct(!visivilityModalAct);
        }}
      />
    </>
  );
};

export { View };
