import React, { useState, useEffect } from 'react';
import { TableDashList } from '../../../../_mexablock/helpers/components/TableDashboardList';

type Props = {
  data: any;
  loadingGrid: boolean;
};

const GraficaEnvios: React.FC<Props> = ({ data, loadingGrid }) => {
  const [statusFilter, setStatusFilter] = useState<string>('shipments');
  const [sortedData, setSortedData] = useState<any[]>([]);

  const sortData = (filter: string) => {
    const sorted = [...data].sort((a, b) => {
      const aValue =
        filter === 'cost'
          ? parseFloat(a[filter]?.replace(/[$,]/g, '') || '0')
          : a[filter] ?? 0;
      const bValue =
        filter === 'cost'
          ? parseFloat(b[filter]?.replace(/[$,]/g, '') || '0')
          : b[filter] ?? 0;

      return bValue - aValue;
    });

    setSortedData(sorted);
  };

  useEffect(() => {
    sortData(statusFilter);
  }, [statusFilter, data]);

  const columns: any = [
    {
      id: 'created_at',
      name: (
        <div className="d-flex justify-content-center align-items-center w-100">
          <button
            className={`btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${
              statusFilter === 'shipments' ? 'active' : ''
            }`}
            onClick={() => setStatusFilter('shipments')}
          >
            Envíos
          </button>
          <button
            className={`btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${
              statusFilter === 'cost' ? 'active' : ''
            }`}
            onClick={() => setStatusFilter('cost')}
          >
            Costos
          </button>
        </div>
      ),
      selector: (row: any) => row.name,
      width: '165px',
    },
    {
      id: 'cash',
      name: (
        <a className="nav-link btn btn-sm btn-bg-secondary text-gray-500 px-4 me-1">
          Ordenar
        </a>
      ),
      selector: (row: any) => row[statusFilter],
      sortable: true,
    },
  ];

  return data.length > 0 ? (
    <div className="card card-flush h-xl-100">
      <div className="card-header pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-dark fs-3">
            Resumen de envíos
          </span>
          <span className="text-gray-400 mt-1 fw-semibold fs-6">
            Detalles de costo
          </span>
        </h3>
      </div>
      <div className="card-body">
        {loadingGrid ? (
          <div className="d-flex align-items-center">
            <div className="text-muted text-center fs-6 w-100 m-5">
              <span className="indicator-progress" style={{ display: 'block' }}>
                Espere por favor...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </div>
          </div>
        ) : (
          <>
            <TableDashList
              key={statusFilter}
              data={sortedData}
              columns={columns}
            ></TableDashList>
          </>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export { GraficaEnvios };
