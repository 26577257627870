import React, { useState } from 'react';
import { SimpleAlert, toAbsoluteUrl } from '../../../../../_mexablock/helpers';
import { NumericFormat } from 'react-number-format';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { validarIMEI } from '../../../../service/operacion/Validacion';

const shcemeIMEI = Yup.object().shape({
  imei: Yup.string()
    .min(14, 'El IMEI debe tener mínimo 14 dígitos.')
    .max(16, 'El IMEI debe tener máximo 16 dígitos.')
    .required('IMEI es requerido.'),
});
// TEST
const View: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    imei: '',
    homologated: '',
    sub_category: '',
    model: '',
    brand: '',
    esim: '',
  });
  const formValidateIMEI = useFormik({
    initialValues: {
      imei: '',
    },
    validationSchema: shcemeIMEI,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      setLoading(true);
      validarIMEI(values)
        .then((res) => {
          const {
            data: { doc },
          } = res;
          setData({
            imei: doc.data.imei.imei,
            homologated: doc.data.imei.homologated,
            sub_category: doc.data.imei.sub_category,
            model: doc.data.device_features.model,
            brand: doc.data.device_features.brand,
            esim: doc.data.imei.soportaESIM,
          });

          setLoading(false);
        })
        .catch((err) => {
          // let resMessageToast: string = '';
          // const {
          //   data: { message, details = null },
          // } = err.response;
          // resMessageToast = `${message}`;
          // if (details.length) {
          //   setStatus(`${details[0]?.message}`);
          // }
          setData({
            imei: '',
            homologated: '',
            sub_category: '',
            model: '',
            brand: '',
            esim: '',
          });
          setLoading(false);
          SimpleAlert(
            'Error al validar el IMEI, revisa que el IMEI esté correcto.',
            3000,
            'error'
          );
        });
    },
  });

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-body p-lg-17">
              <div className="mb-18">
                <div className="mb-10">
                  <div className="text-center mb-15">
                    <h3 className="fs-2hx text-dark mb-5">Validar IMEI</h3>

                    <div className="fs-5 text-muted fw-semibold">
                      Ingrese el IMEI para validar si el equipo es compatible.
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center w-100">
                <div className="d-flex flex-column align-items-start justift-content-center flex-equal me-5 w-50 h-300px">
                  <form
                    id="kt_form_validate"
                    className="form w-100"
                    action="#"
                    onSubmit={formValidateIMEI.handleSubmit}
                  >
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        IMEI a validar
                      </label>
                      {formValidateIMEI.touched.imei &&
                        formValidateIMEI.errors.imei && (
                          <div className="fv-plugins-message-container text-danger ms-5">
                            <span role="alert">
                              {formValidateIMEI.errors.imei}
                            </span>
                          </div>
                        )}
                    </div>

                    {/* <h1 className="fw-bold fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-5">
                      IMEI a validar
                    </h1> */}
                    {/* {formValidateIMEI.touched.imei &&
                      formValidateIMEI.errors.imei && (
                        <div className="fv-plugins-message-container text-danger ms-5">
                          <span role="alert">
                            {formValidateIMEI.errors.imei}
                          </span>
                        </div>
                      )} */}
                    <div className="position-relative w-100">
                      <NumericFormat
                        name="imei"
                        className="form-control text-gray-700 placeholder-gray-400 mw-500px"
                        value={formValidateIMEI.values.imei}
                        decimalScale={0}
                        decimalSeparator="."
                        minLength={14}
                        maxLength={16}
                        fixedDecimalScale
                        placeholder="IMEI"
                        prefix=""
                        thousandSeparator=""
                        onValueChange={(values) => {
                          const { value } = values;
                          formValidateIMEI.setFieldValue(
                            'imei',
                            value ? value : ''
                          );
                        }}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary mt-5"
                      data-kt-users-modal-action="submit"
                      disabled={loading}
                    >
                      {!loading && (
                        <span className="indicator-label">Consultar</span>
                      )}
                      {loading && (
                        <span
                          className="indicator-progress pt-100px"
                          style={{ display: 'block' }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </form>
                </div>
                <div className="d-flex flex-column align-items-start justift-content-center flex-equal me-5 min-h-400px">
                  {loading ? (
                    <div className="text-muted text-center fs-6 w-100 m-5">
                      <span
                        className="indicator-progres mt-15"
                        style={{ display: 'block' }}
                      >
                        Espere por favor...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </div>
                  ) : data.imei.length <= 0 ? (
                    <>
                      <div className=" w-100 text-center">
                        <span className="badge badge-light-primary fs-5">
                          Como obtener el IMEI
                        </span>
                      </div>
                      <div className=" w-100 text-center">
                        <img
                          alt="GIF"
                          src={toAbsoluteUrl('/media/gif/validacion.gif')}
                          className="h-400px"
                          style={{ borderRadius: '10px' }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="w-100">
                      <div className="mb-7">
                        <h5 className="mb-3">Resultado del IMEI</h5>

                        <div className="d-flex align-items-center mb-1">
                          <span className="fw-bold text-gray-800 text-hover-primary me-2">
                            {data.imei}{' '}
                          </span>

                          {data.homologated === 'COMPATIBLE' ||
                          data.homologated === 'COMPATIBLE HOMOLOGADO' ||
                          data.homologated === 'COMPATIBLE PROBADO' ? (
                            <span className="badge badge-light-success">
                              COMPATIBLE
                            </span>
                          ) : (
                            <span className="badge badge-light-danger">
                              NO COMPATIBLE
                            </span>
                          )}
                        </div>

                        <span className="fw-semibold text-gray-600">
                          {data.sub_category}
                        </span>
                        <div className="fw-bold mt-2">
                          Soporta ESIM:{' '}
                          <span className="fw-semibold text-gray-600">
                            {data.esim}
                          </span>
                        </div>
                      </div>

                      <div className="separator separator-dashed mb-7"></div>

                      <div className="mb-7">
                        <h5 className="mb-3">
                          Características del dispositivo
                        </h5>

                        <div className="mb-0">
                          <div className="fw-semibold text-gray-600 d-flex align-items-center">
                            {data.model}
                          </div>

                          <div className="fw-semibold text-gray-600">
                            {data.brand}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
