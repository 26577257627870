import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getAllCuentas() {
  const response = await axios.get(
    `${API_URL}catalogos/account?limit=1000&page=1`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getAllStateCost() {
  const response = await axios.get(
    `${API_URL}operation/sim-shipments/state-cost`
  );
  const data = response.data.doc.data;
  return data;
}
